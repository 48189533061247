export const homeObjOne = {
    id: 'projects',
    img1: require('../../images/dancebox.png'),
    img2: require('../../images/diabuddy.png'),
    img3: require('../../images/stir.png'),
    // img4: require('../../images/instacat.png'),
    img5: require('../../images/hungrytoby.png'),
}

export const homeObjTwo = {
    id: 'resume',
    img6: require('../../images/TaylorSouthlineResume.png'),
}

export const homeObjThree = {
    id: 'about',
    img6: require('../../images/resumeimg.png'),
}

export const homeObjFour = {
    id: 'tech',
}
